import React from 'react';

import CustomersFeedbacks from '../../components/Home/v2/CustomersFeedbacks';
import MenuBar from '../../components/Home/MenuBar';
import Footer from '../../components/Home/Footer';
import { Container } from '../../components/Home/v2/StyledComponents';

class FeedbacksPage extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Container routeSlug="FeedbacksPage">
        <MenuBar />
        <CustomersFeedbacks />
        <Footer />
      </Container>
    );
  }
}

export default FeedbacksPage;
